import { useTab } from "../../../components/hooks";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import ModuleMachineDailySchedule from "./dailySchedule";
import ModuleMachineUploadRecord from "./uploadRecord";

export default function BackMachine(props) {
  const crumbItem = [
    {
      key: 1,
      label: "機種日程表",
    },
  ];
  const { activeIndex, renderTab } = useTab({
    moduleType: "underline",
    data: [
      {
        key: 1,
        label: "日程表",
        value: 0,
        type: "machineDailySchedule",
      },
      {
        key: 2,
        label: "上傳紀錄",
        value: 1,
        type: "machineUploadRecord",
      },
    ],
    matchTab: [
      "/machine/dailySchedule",
      "/machine/uploadRecord",
      "/machine/shipManagement",
    ],
  });

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">機種日程表</h2>

      <div className="w-full bg-white border-round-lg p-2 sm:p-3">
        {renderTab}

        {activeIndex === 0 ? (
          <ModuleMachineDailySchedule />
        ) : activeIndex === 1 ? (
          <ModuleMachineUploadRecord />
        ) : null}
      </div>
    </>
  );
}
