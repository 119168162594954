import JSZip from "jszip";
import { saveAs } from "file-saver";

const xlsxCellSerial = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
  "BA",
  "BB",
  "BC",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BK",
  "BL",
  "BM",
  "BN",
  "BO",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BX",
  "BY",
  "BZ",
  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CJ",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CP",
  "CQ",
  "CR",
  "CS",
  "CT",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DA",
  "DB",
  "DC",
  "DD",
  "DE",
  "DF",
  "DG",
  "DH",
  "DI",
  "DJ",
  "DK",
  "DL",
  "DM",
  "DN",
  "DO",
  "DP",
  "DQ",
  "DR",
  "DS",
  "DT",
  "DU",
  "DV",
  "DW",
  "DX",
  "DY",
  "DZ",
  "EA",
  "EB",
  "EC",
  "ED",
  "EE",
  "EF",
  "EG",
  "EH",
  "EI",
  "EJ",
  "EK",
  "EL",
  "EM",
  "EN",
  "EO",
  "EP",
  "EQ",
  "ER",
  "ES",
  "ET",
  "EU",
  "EV",
  "EW",
  "EX",
  "EY",
  "EZ",
];

const createXlsxColumn = ({ ws, column = null, value = "", styles = {} }) => {
  if (column === null) return;

  let attribute = { value, ...styles };

  Object.entries(attribute).forEach(([key, val]) => {
    ws.getCell(column)[key] = val;
  });
};

const createXlsxCellStyle = ({
  font_sz = 12,
  font_color = "000000",
  font_bold = false,
  font_underline = false,
  wrapText = true,
  align_h = "left",
  fill_color = "ffffff",
  border_color = "000000",
  other = {},
} = {}) => {
  return {
    font: {
      size: font_sz,
      color: { argb: font_color },
      bold: font_bold,
      underline: font_underline,
    },
    alignment: {
      horizontal: align_h,
      vertical: "middle",
      wrapText: wrapText,
    },
    border: {
      top: { style: "thin", color: { argb: border_color } },
      bottom: { style: "thin", color: { argb: border_color } },
      left: { style: "thin", color: { argb: border_color } },
      right: { style: "thin", color: { argb: border_color } },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: fill_color,
      },
    },
    ...other,
  };
};

// 設定欄位寬度
const createColumnWidth = ({ ws, columns = [] } = {}) => {
  columns.forEach((item) => {
    ws.getColumn(item.column).width = item.width;
  });
};

// 設定欄位高度
const createColumnHeight = ({ ws, columns = [] } = {}) => {
  columns.forEach((item) => {
    ws.getRow(item.column).height = item.height;
  });
};

// 設定合併欄位
const createColumnMerge = ({ ws, columns = [] } = {}) => {
  columns.forEach((item) => {
    ws.mergeCells(item.column);
  });
};

const downloadZip = async ({
  filename = "template",
  fileBlob = [],
  finishAction = () => {},
}) => {
  let zip = new JSZip();

  // 製作檔案
  fileBlob.forEach((item) => {
    zip.file(item.filename, item.blob);
  });

  // 把打包內容非同步轉成blob二進位制格式
  zip
    .generateAsync({ type: "blob" }, function updateCallback(metadata) {
      if (metadata.percent == 100) {
        // ServiceCom.switchLoading(false);
      }
    })
    .then(function (content) {
      // content就是blob資料，這裡以example.zip名稱下載
      // 使用了FileSaver.js
      saveAs(content, `${filename}.zip`);
      finishAction();
    });
};

const parseSheetName = (name) => {
  let excludeChar = ["*", "?", ":", "\\", "/", "[", "]"];
  excludeChar.forEach((item) => (name = name.replaceAll(item, "")));
  return name;
};

export {
  xlsxCellSerial,
  createXlsxColumn,
  createXlsxCellStyle,
  createColumnWidth,
  createColumnHeight,
  createColumnMerge,
  downloadZip,
  parseSheetName,
};
