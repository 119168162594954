import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { ShipAPI } from "../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
  useConfirm,
} from "../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import {
  setPageSetting,
  formatTreeData,
  decimalAddZero,
} from "../../../service/common";
import { system_dropdown, system_table } from "../../../service/system";
import { option_enabled } from "../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

export default function BackShipList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const crumbItem = [{ key: 1, label: "出貨管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      modelname: "",
      startDate: new Date(),
      endDate: new Date(moment().add(10, "days")),
      page: 1,
      size: system_table.size,
    },
    pagePath = "/ship";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { confirmOption, setConfirmOption, renderConfirm } = useConfirm();

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        startDate: moment(search.startDate).format("YYYY-MM-DD"),
        endDate: moment(search.endDate).format("YYYY-MM-DD"),
      };

      ShipAPI.getShipRecordsDataList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          data = data.map((item) => ({
            ...item,
            numbers_format: decimalAddZero({
              rawVal: item.numbers ?? 0,
              decimalShowRaw: true,
            }),
          }));
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  const deleteAction = (data) => {
    dispatch(setProgress(true));

    let jsonData = { id: data.id };

    ShipAPI.deleteShipRecordsData({
      data: jsonData,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  useEffect(() => {
    const renderBtnAction = (data, rowIndex) => (
      <>
        <Button
          className="p-button-main p-button-sm mr-2"
          type="button"
          label="編輯"
          onClick={() => route_navigate(`/back${pagePath}/${data.id}`)}
        />
        <Button
          id={`button_delete_${rowIndex}`}
          className="p-button-danger p-button-sm"
          type="button"
          label="刪除"
          onClick={() =>
            setConfirmOption({
              setting: {
                target: document.getElementById(`button_delete_${rowIndex}`),
                message: "確認是否刪除",
                icon: "pi pi-exclamation-triangle",
                visible: !confirmOption.setting.visible,
                acceptLabel: "是",
                rejectLabel: "否",
                accept: () => deleteAction(data),
                reject: () => null,
              },
              reOpen: !!confirmOption.setting.visible,
            })
          }
        />
      </>
    );

    setTbColumns([
      {
        field: "date",
        header: "日期",
      },
      {
        field: "modelname",
        header: "機種",
      },
      {
        field: "numbers_format",
        header: "出貨數量",
      },
      {
        field: "id",
        header: "進階",
        className: "w-9rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data, { rowIndex }) => renderBtnAction(data, rowIndex),
      },
    ]);
  }, [pagePath]);

  return (
    <>
      {renderConfirm}

      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">出貨管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-8 lg:col-4 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  起訖日期
                </label>
                <div className="flex align-items-center w-full">
                  <Calendar
                    className="w-full"
                    name="startDate"
                    value={new Date(filterData.startDate)}
                    maxDate={new Date(filterData.endDate)}
                    onChange={(e) => changeFilter(e)}
                    readOnlyInput
                    // showButtonBar
                  />
                  <span className="mx-2">-</span>
                  <Calendar
                    className="w-full"
                    name="endDate"
                    value={new Date(filterData.endDate)}
                    minDate={new Date(filterData.startDate)}
                    onChange={(e) => changeFilter(e)}
                    readOnlyInput
                    // showButtonBar
                  />
                </div>
              </div>
              <div className="col-6 md:col-4 lg:col-2 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  機種
                </label>
                <InputText
                  className="w-full"
                  name="modelname"
                  value={filterData.modelname}
                  onChange={(e) => changeFilter(e)}
                />
              </div>
              <div
                className={classNames(
                  "col-6 md:col-12 lg:col-6 flex align-items-end mt-2 lg:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-0 md:ml-auto lg:ml-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                />

                <Button
                  className="p-button-warning p-button-sm w-auto ml-auto md:ml-2 lg:ml-auto ml-2 px-3"
                  type="button"
                  label="新增"
                  onClick={() => route_navigate(`/back${pagePath}/add`)}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true },
        })}
      </div>
    </>
  );
}
