import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout } from "../../redux/slice/system";

import ImgLogo from "../../images/logo.png";

import { Sidebar } from "primereact/sidebar";
import { PanelMenu } from "primereact/panelmenu";
import { classNames } from "primereact/utils";

export default function BackSidebar(props) {
  let { LayoutCss } = props;
  const route_location = useLocation();
  const route_navigate = useNavigate();
  const redux_user = useSelector((state) => state.user);
  const redux_system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  const [menuItem, setMenuItem] = useState([]);

  const renderExpand = (item, options, layer) => {
    return (
      <>
        <a
          className={options.className}
          style={{ paddingLeft: 1 + layer * 1.13 + "rem" }}
          onClick={options.onClick}
        >
          {options.iconClassName !== "p-menuitem-icon" && (
            <span className={classNames(options.iconClassName)}></span>
          )}
          <span className={classNames(options.labelClassName, "mr-auto")}>
            {item.label}
          </span>
          <i
            className={`pi pi-angle-down -rotate-${
              item.expanded ? "180" : "0"
            } transition-all transition-duration-200 transition-ease-in-out`}
          ></i>
        </a>
      </>
    );
  };

  const renderLink = (item, options, layer) => {
    return (
      <>
        <a
          className={options.className}
          style={{ paddingLeft: 1 + layer * 1.13 + "rem" }}
          onClick={options.onClick}
        >
          {/* <span className={classNames(options.iconClassName)}></span> */}
          <span className={classNames(options.labelClassName, "mr-auto")}>
            {item.label}
          </span>
        </a>
      </>
    );
  };

  const matchExpand = (path) => {
    let { pathname } = route_location;

    return pathname.indexOf(path) !== -1;
  };

  const matchPath = (path) => {
    let { pathname } = route_location;

    return pathname.indexOf(path) !== -1 ? "menu-active" : "";
  };

  // 設定 menu 項目
  useEffect(() => {
    function formatRoutes(rawData) {
      let temp = [];

      function formatRoute(childs, layer) {
        let tempChild = [];
        layer++;

        childs.forEach((child) => {
          if (child.items && child.items.length > 0) {
            tempChild.push({
              label: child.label,
              icon: child.icon,
              expanded: matchExpand(child.path),
              template: (item, options) => renderExpand(item, options, layer),
              items: formatRoute(child.items, layer),
            });
          } else {
            tempChild.push({
              label: child.label,
              icon: child.icon,
              className: matchPath(child.path),
              template: (item, options) => renderLink(item, options, layer),
              command: (e) => {
                route_navigate(child.path);
              },
            });
          }
        });

        return tempChild;
      }

      rawData.forEach((route) => {
        if (route.items && route.items.length > 0) {
          temp.push({
            label: route.label,
            icon: route.icon,
            expanded: matchExpand(route.path),
            template: (item, options) => renderExpand(item, options),
            items: formatRoute(route.items, 0),
          });
        } else {
          temp.push({
            label: route.label,
            icon: route.icon,
            className: matchPath(route.path),
            command: (e) => {
              route_navigate(route.path);
            },
          });
        }
      });

      return temp;
    }

    let routes = [
      {
        label: "機種日程表",
        path: "/back/machine",
      },
      {
        label: "出貨管理",
        path: "/back/ship",
      },
      {
        label: "設定管理",
        path: "/back/setting",
        items: [
          {
            label: "機種管理",
            path: "/back/setting/machineType",
          },
        ],
      },
      ...([3, 9].includes(redux_user.userDetail?.type)
        ? [
            {
              label: "帳號管理",
              path: "/back/account",
            },
          ]
        : []),
    ];
    setMenuItem(formatRoutes(routes));
  }, [route_location.pathname, redux_user]);

  return (
    <>
      <div className={`${LayoutCss["layout-sidebar"]}`}>
        <Sidebar
          visible={redux_system.backLayout.isSideShow}
          modal={!redux_system.backLayout.isSideFixed}
          dismissable={!redux_system.backLayout.isSideFixed}
          showCloseIcon={false}
          onHide={() =>
            dispatch(
              setBackLayout({
                isSideShow: false,
              })
            )
          }
        >
          <div className={`${LayoutCss["layout-logo"]} mt-3`}>
            <img className="w-12 opacity-70" src={ImgLogo} />

            <h3 className="text-main text-center text-xl mt-3">
              工廠自動化後台
            </h3>
          </div>

          <PanelMenu model={menuItem} style={{ width: "100%" }} />
        </Sidebar>
      </div>
    </>
  );
}
