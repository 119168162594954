import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useHookForm } from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

import { createXlsxDailyProduceRecord } from "./xlsx/dailyProduceRecord";
import { FormCalendar } from "../../../../components/formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopMachineDailyScheduleExport({
  prePopOption,
  closePrePop,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    date: moment().format("YYYY/MM/DD"),
  };
  const { handleSubmit, setValue, trigger, yup, setSchema, errors, setting } =
    useHookForm({
      defaultValues,
    });

  useEffect(() => {
    setSchema(
      yup
        .object({
          date: yup.string().required("必填").nullable(),
        })
        .required()
    );
  }, []);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let params = {
      date: moment(submitData.date).format("YYYY-MM-DD"),
      days: moment(submitData.date).daysInMonth(),
    };

    MachineAPI.getDailyProduceRecordList({
      data: params,
    }).then((result) => {
      let { Response, data, message } = result;
      if (Response === 1) {
        // 沒有日程資料但有出貨紀錄
        let shipKey = Object.keys(data.ship_records);
        shipKey.forEach((key) => {
          if (!data.daily_produce_record[key])
            data.daily_produce_record[key] = [];
        });

        data = Object.entries(data.daily_produce_record).map(
          ([modelKey, val]) => {
            let produce_amount = val.reduce(
                (pre, cur) => (pre += cur.latest_number),
                0
              ),
              ship_amount = (data.ship_records[modelKey] || []).reduce(
                (pre, cur) => (pre += cur.numbers),
                0
              );

            return {
              modelKey,
              produce_amount,
              ship_amount,
              ...val.reduce(
                (pre, cur) => ({
                  ...pre,
                  [moment(cur.date).format("MM/DD")]: cur,
                }),
                {}
              ),
            };
          }
        );
        data.sort(function (pre, next) {
          return pre.modelKey.localeCompare(next.modelKey);
        });

        let headColumns = [
          {
            key: "modelKey",
            header: "機種",
          },
          {
            key: "produce_amount",
            header: "生產計劃數量",
          },
          {
            key: "ship_amount",
            header: "已出貨數量",
          },
        ];
        let startDay = Number(moment(submitData.date).format("DD")),
          endDay = Number(moment(submitData.date).endOf("month").format("DD"));
        for (let i = startDay; i <= endDay; i++) {
          let nowDate = moment(submitData.date)
            .add(i - startDay, "days")
            .format("MM/DD");
          headColumns.push({
            key: nowDate,
            header: nowDate,
            // body: (data) => <>{data[nowDate]?.latest_number ?? "-"}</>,
          });
        }

        exportExcel({ submitData, data, headColumns });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  const exportExcel = async ({ submitData, data, headColumns }) => {
    const workbook = new ExcelJS.Workbook(),
      workbookName = "日程表";

    createXlsxDailyProduceRecord({
      title: workbookName,
      data: {
        workbook,
        headColumns,
        data,
      },
    });

    const mainBuffer = await workbook.xlsx.writeBuffer();
    const mainFileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const mainBlob = new Blob([mainBuffer], { type: mainFileType });
    saveAs(mainBlob, `${workbookName}.xlsx`);
  };

  return (
    <>
      <div className="mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              匯出起始日期
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                }}
              />
            </div>
          </div>

          <div className="text-right mt-3">
            <Button
              className="p-button-secondary p-button-outlined px-3"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: "export" })}
            />
            <Button
              className="p-button-main ml-2 px-3"
              type="submit"
              label="匯出"
            />
          </div>
        </form>
      </div>
    </>
  );
}
