import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout, setToast } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { usePop } from "../hooks";
import moment from "moment";

import BackSidebar from "./sidebar";
import BackTopbar from "./topbar";
import ModulePopCheckToken from "./popCheckToken";
import ModulePopCheckPassword from "./popCheckPassword";

import LayoutCss from "../../styles/components/layout.module.scss";

export default function BackLayoutMain(props) {
  const redux_system = useSelector((state) => state.system);
  const redux_user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const route_location = useLocation();
  const route_navigate = useNavigate();
  const { isPop, openPop, closePop, renderDialog } = usePop([
    {
      key: "refreshToken",
      title: () => "確認",
      isOpen: false,
    },
    {
      key: "checkPassword",
      title: () => "帳戶安全性通知提醒",
      isOpen: false,
    },
  ]);
  // 螢幕大小設定
  const [screen, setScreen] = useState({
    width: null,
    height: null,
  });
  // 是否有權限
  const [authSetting, setAuthSetting] = useState({
    isValid: null,
    optionPath: {
      3: ["/back/account"],
    },
    commonPath: ["/back/password", "/back/machine", "/back/setting"],
  });

  // 偵測是否有登入資料，沒有則導回登入頁
  useEffect(() => {
    let user_local = localStorage.getItem("user");
    if (!redux_user.userDetail && !user_local) {
      route_navigate("/login");
    } else {
      let { pathname } = route_location;
      if (pathname === "/back") {
        route_navigate("/back/patient");
      }
    }
  }, []);

  // 偵測螢幕大小
  useEffect(() => {
    const resize = () => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", resize);

    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  // 依照螢幕大小控制 sidebar
  useEffect(() => {
    if (!screen.width || !screen.height) {
      return;
    }

    if (screen.width <= 768) {
      dispatch(
        setBackLayout({
          isSideShow: false,
          isSideFixed: false,
        })
      );
    } else {
      dispatch(
        setBackLayout({
          isSideFixed: true,
        })
      );
    }
  }, [screen]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (redux_user.userDetail) {
        let diff = moment().diff(redux_user.userDetail?.now);
        if (diff >= 3 * 60 * 60 * 1000 || !redux_user.userDetail?.now) {
          dispatch(
            setToast({
              severity: "error",
              summary: "超過使用時限，請重新登入",
              detail: "",
            })
          );
          dispatch(setUser(null));
          route_navigate("/login");
        } else if (
          diff >= 3 * 60 * 60 * 1000 - 15 * 60 * 1000 ||
          !redux_user.userDetail?.now
        ) {
          openPop({
            type: "refreshToken",
            data: null,
          });
        }
      } else {
        clearInterval(timer);
      }
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [redux_user.userDetail]);

  useEffect(() => {
    if (!redux_user.userDetail?.is_need_change_password) return;

    openPop({
      type: "checkPassword",
      data: null,
    });
  }, [redux_user.userDetail]);

  // 判斷頁面權限
  useEffect(() => {
    if (!redux_user.userDetail) return;

    let isValid = false;
    if (redux_user.userDetail?.type === 9) isValid = true;
    else {
      isValid = (authSetting.optionPath[redux_user.userDetail?.type] || [])
        ?.concat(authSetting.commonPath)
        .some((path) => route_location.pathname.includes(path));
    }
    setAuthSetting((state) => ({ ...state, isValid }));
  }, [redux_user]);

  /*
   * 無權限頁面跳轉
   * 已登入 => 跳轉上一頁
   * 未登入 => 跳轉登入
   */
  useEffect(() => {
    if (redux_user.userDetail && authSetting.isValid === false)
      route_navigate(-1);
    else if (authSetting.isValid === false) route_navigate("login");
  }, [authSetting]);

  return (
    <>
      <div
        className={`${LayoutCss["back-layout-wrap"]} ${
          redux_system.backLayout.isSideFixed &&
          `${LayoutCss["layout-sidebar-fixed"]}`
        } ${
          redux_system.backLayout.isSideShow &&
          `${LayoutCss["layout-sidebar-show"]}`
        }`}
      >
        {/* 左側區塊 */}
        <BackSidebar LayoutCss={LayoutCss} />

        <div className={`${LayoutCss["layout-body"]}`}>
          {/* 上方區塊 */}
          <BackTopbar LayoutCss={LayoutCss} />

          <div className={`${LayoutCss["layout-body-content"]}`}>
            {/* 內容區塊，依照 route 顯示不同元件 */}
            {redux_user.userDetail && authSetting.isValid && <Outlet />}
          </div>
        </div>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-30rem",
        children: (
          <>
            {isPop.refreshToken?.isOpen && (
              <ModulePopCheckToken closePrePop={closePop} />
            )}
            {isPop.checkPassword?.isOpen && (
              <ModulePopCheckPassword closePrePop={closePop} />
            )}
          </>
        ),
      })}
    </>
  );
}
