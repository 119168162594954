import request from "../request";
import Url from "../url/account";

const getAllMemebrList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAllMemebrList + path, data, options);
};
const getMemebrDetail = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMemebrDetail + path, data, options);
};
const setMemberData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setMemberData + path, data, options);
};
const updateMemberData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateMemberData + path, data, options);
};
const disableAccount = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.disableAccount + path, data, options);
};
const reOpenAccount = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.reOpenAccount + path, data, options);
};
const checkUsername = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.checkUsername + path, data, options);
};

export default {
  getAllMemebrList,
  getMemebrDetail,
  setMemberData,
  updateMemberData,
  disableAccount,
  reOpenAccount,
  checkUsername,
};
