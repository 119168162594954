import BackLayoutMain from "../components/backLayout/main";

import BackChangePassword from "../page/back/password";
import BackAccountList from "../page/back/account";
import BackAccountDetail from "../page/back/account/detail";
import BackMachine from "../page/back/machine";
import BackShipList from "../page/back/ship";
import BackShipDetail from "../page/back/ship/detail";
import BackSettingMachineType from "../page/setting/machineType";

const routes = [
  {
    path: "back",
    element: <BackLayoutMain />,
    children: [
      {
        path: "password",
        element: <BackChangePassword />,
      },
      {
        path: "account",
        element: <BackAccountList />,
      },
      {
        path: "account/:detailid",
        element: <BackAccountDetail />,
      },
      {
        path: "machine",
        element: <BackMachine />,
      },
      {
        path: "ship",
        element: <BackShipList />,
      },
      {
        path: "ship/:detailid",
        element: <BackShipDetail />,
      },
      {
        path: "setting/machineType",
        element: <BackSettingMachineType />,
      },

      {
        path: "*",
        element: <>無畫面</>,
      },
    ],
  },
];

export default {
  routes,
};
