import request from "../request";
import Url from "../url/ship";

const getShipRecordsDataList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getShipRecordsDataList + path, data, options);
};
const getShipRecordsDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getShipRecordsDetails + path, data, options);
};
const setShipRecordsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setShipRecordsData + path, data, options);
};
const updateShipRecordsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateShipRecordsData + path, data, options);
};
const deleteShipRecordsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteShipRecordsData + path, data, options);
};

export default {
  getShipRecordsDataList,
  getShipRecordsDetails,
  setShipRecordsData,
  updateShipRecordsData,
  deleteShipRecordsData,
};
