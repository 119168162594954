import request from "../request";
import Url from "../url/setting";

const getModelTypeDataList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getModelTypeDataList + path, data, options);
};
const getModelTypeDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getModelTypeDetails + path, data, options);
};
const getModelTypesDistinct = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getModelTypesDistinct + path, data, options);
};
const setModelTypeData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setModelTypeData + path, data, options);
};
const deleteModelTypeData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteModelTypeData + path, data, options);
};
const importModelTypeData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.importModelTypeData + path, data, options);
};

export default {
  getModelTypeDataList,
  getModelTypeDetails,
  getModelTypesDistinct,
  setModelTypeData,
  deleteModelTypeData,
  importModelTypeData,
};
