function useFilterList() {
  /*
    取得 列表 篩選資料
    @params data: 被篩選的資料
    @params filterKey: 比對資料的 Key
    @params serachTerm: 搜尋字串
    @params isConsistent: 是否完全比對 (true/false)
  */
  const getFilterListData = ({
    data = [],
    filterKey = "",
    serachTerm = "",
    isConsistent = false,
  }) =>
    (data || []).filter((item) => {
      if (serachTerm === "" || serachTerm === null || serachTerm === undefined)
        return true;
      if (isConsistent) return item[filterKey] === serachTerm;
      else
        return String(item[filterKey])
          .toUpperCase()
          ?.includes(serachTerm.toUpperCase());
    });

  /*
    取得 Tree 篩選資料
    @params data: 被篩選的資料
    @params filterKey: 比對資料的 Key
    @params serachTerm: 搜尋字串
    @params isConsistent: 是否完全比對 (true/false)
  */
  const getFilterTreeData = ({
    data = [],
    filterKey = "",
    serachTerm = "",
    isConsistent = false,
  }) => {
    let reVal = [];
    (data || []).forEach(({ children, ...item }) => {
      let temp = null;
      if (children) {
        temp = getFilterTreeData({
          data: children,
          filterKey,
          serachTerm,
          isConsistent,
        });
      }

      if (temp && temp.length > 0) {
        reVal.push({
          ...item,
          children: temp,
        });
      } else if (
        serachTerm === "" ||
        serachTerm === null ||
        serachTerm === undefined ||
        (isConsistent && item.data[filterKey] === serachTerm) ||
        (!isConsistent && item.data[filterKey]?.includes(serachTerm))
      ) {
        reVal.push({
          ...item,
          children: [],
        });
      }
    });

    return reVal;
  };

  return { getFilterListData, getFilterTreeData };
}

export default useFilterList;
