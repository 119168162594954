import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { AccountAPI } from "../../../axios/api";
import { useAbortedEffect, useHookForm } from "../../../components/hooks";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import {
  FormInputtext,
  FormInputnumber,
  FormDropdown,
  FormInputpassword,
} from "../../../components/formElement/index";
import { formatResData, checkFormUpdate } from "../../../service/common";
import { option_enabled } from "../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackAccountDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    {
      key: 1,
      label: "帳號管理",
      command: () => route_navigate("/back/account"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  // 明細資料
  const [apiData, setApiData] = useState({});
  // 表單欄位預設值
  const defaultValues = {
    username: "",
    password: "",
    repassword: "",
    name: "",
    type: 1,
    enabled: 1,
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  // 監聽欄位
  const watchColumn = {
    password: watch("password"),
    repassword: watch("repassword"),
  };

  // 動態判斷欄位
  useEffect(() => {
    if (setting.isSubmitted) trigger(["password", "repassword"]);
  }, [watchColumn.password, watchColumn.repassword]);

  useEffect(() => {
    setSchema(
      yup
        .object({
          username: yup.string().required("必填"),
          password: yup
            .string()
            .test(
              "isValidPass",
              "必填",
              (value, { from: [{ value: data }] }) => {
                return route_params.detailid === "add" || data.repassword
                  ? value && value === data.repassword
                  : true;
              }
            )
            .nullable(),
          repassword: yup
            .string()
            .test(
              "isValidPass",
              "必填",
              (value, { from: [{ value: data }] }) => {
                return route_params.detailid === "add" || data.password
                  ? value && value === data.password
                  : true;
              }
            )
            .nullable(),
        })
        .required()
    );
  }, []);

  useAbortedEffect(
    (signal) => {
      if (!reload.list || route_params.detailid === "add") {
        return;
      }

      dispatch(setProgress(true));

      let params = { id: route_params.detailid };

      AccountAPI.getMemebrDetail({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;
        if (Response === 1) {
          data.data = data.content;
          let temp = formatResData(defaultValues, data);
          setApiData(temp);
          reset(temp);
          setReload((state) => ({ ...state, list: false }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let isAdd = route_params.detailid === "add",
      jsonData = {
        ...submitData,
      };

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/account");
      return;
    }

    if (!submitData.repassword) {
      delete jsonData.password;
    }
    delete jsonData.repassword;

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = AccountAPI.setMemberData({
        data: jsonData,
      });
      toast = "新增";
    } else {
      jsonData.id = Number(route_params.detailid);
      promise = AccountAPI.updateMemberData({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        route_navigate("/back/account");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">帳號管理 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>人員姓名
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "name",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="username"
              className={classNames(
                { "p-error": errors.username },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>帳號
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "username",
                  disabled: route_params.detailid !== "add",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="password"
              className={classNames(
                { "p-error": errors.password },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              {route_params.detailid === "add" && (
                <span className="text-red-400 mr-1">*</span>
              )}
              密碼
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputpassword
                setting={setting}
                data={{
                  name: "password",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="repassword"
              className={classNames(
                { "p-error": errors.repassword },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              {route_params.detailid === "add" && (
                <span className="text-red-400 mr-1">*</span>
              )}
              確認密碼
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputpassword
                setting={setting}
                data={{ name: "repassword" }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="enabled"
              className={classNames(
                { "p-error": errors.enabled },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>啟用狀態
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                setting={setting}
                data={{
                  name: "enabled",
                  options: option_enabled,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                }}
              />
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/account")}
            />
            <Button
              type="submit"
              label="儲存"
              className="p-button-main ml-2 px-3"
            />
          </div>
        </form>
      </div>
    </>
  );
}
