import { Button } from "primereact/button";

export default function ModulePopConfirm({
  prePopOption,
  closePrePop,
  confirmAction,
}) {
  return (
    <>
      <div className="mx-3">
        <div className="text-2xl text-center font-bold mb-4">
          請確認是否覆蓋原有資料
        </div>

        <div className="text-center mb-4">
          <div className="inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white border-circle bg-orange-500">
            ！
          </div>
        </div>

        <div className="text-center">
          <div className="inline-block text-left text-2xl line-height-3 px-4">
            <div className="text-blue-800 font-bold">
              {prePopOption.data.date}
            </div>
            <div>
              已新增過『
              <span className="text-blue-800 font-bold">
                {prePopOption.data.modelname}
              </span>
              』的出貨記錄
            </div>
            <div>
              出貨數量：
              <span className="text-blue-800 font-bold mr-3">
                {prePopOption.data.numbers}
              </span>
              (變更為：
              <span className="text-red-300 font-bold">
                {prePopOption.data.numbers_new}
              </span>
              )
            </div>
          </div>
        </div>

        <div className="text-center mt-5">
          <Button
            className="p-button-secondary w-auto mr-4 px-4"
            type="button"
            label="否"
            onClick={() => closePrePop({ type: "confirm" })}
          />
          <Button
            className="p-button-danger w-auto px-4"
            type="button"
            label="是"
            onClick={() => {
              closePrePop({ type: "confirm" });
              confirmAction({ id: prePopOption.data.id });
            }}
          />
        </div>
      </div>
    </>
  );
}
