import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useTable,
  useSearch,
} from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import moment from "moment";

import { setPageSetting } from "../../../../service/common";
import { system_table } from "../../../../service/system";

export default function ModuleMachineUploadRecord() {
  const dispatch = useDispatch();
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    setTbFirstIndex,
    setTbTotalRecords,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
    },
    pagePath = "/machineUploadRecord";
  const { filterData, setFilterData, search, setSearch, renderSearchWrap } =
    useSearch({
      defaultFilter,
      pagePath: [pagePath],
      tbSetting: { setTbFirstIndex, setTbRows },
      reloadAction: () => setReload((state) => ({ ...state, list: true })),
    });

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = { ...search };

      MachineAPI.getDailyImportFileRecordList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          data = data.map((item) => ({
            ...item,
            date: moment(`${item.year}/${item.month}/01`).format("YYYY/MM"),
          }));
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }
        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    setTbColumns([
      {
        field: "username",
        header: "上傳人員",
      },
      {
        field: "date",
        header: "年度/月份",
      },
      {
        field: "filename",
        header: "檔名",
      },
      {
        field: "emailtime",
        header: "檔案寄送時間",
      },
      {
        field: "createdatetime",
        header: "上傳時間",
      },
    ]);
  }, []);

  return (
    <>
      <div>
        {renderTable({
          className: "mt-2",
          options: {
            scrollable: true,
          },
        })}
      </div>
    </>
  );
}
