import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useHookForm,
  useUploadXlsx,
} from "../../../../components/hooks";
import { MachineAPI, SettingAPI } from "../../../../axios/api";
import moment from "moment";

import { FormCalendar } from "../../../../components/formElement";
import { isExistDate } from "../../../../service/common";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopMachineDailyScheduleImport({
  prePopOption,
  closePrePop,
  setPreReload,
}) {
  const dispatch = useDispatch();
  const [modelList, setModelList] = useState([]);
  // 表單欄位預設值
  const defaultValues = {
    date: moment().format("YYYY/MM/DD"),
    emailtime: null,
    file: null,
  };
  const { handleSubmit, setValue, trigger, yup, setSchema, errors, setting } =
    useHookForm({
      defaultValues,
    });
  const { renderUpload } = useUploadXlsx({
    columns: [
      {
        key: "aaa",
        label: "機型",
        isRequired: true,
      },
      {
        key: "bbb",
        label: "機種",
        isRequired: true,
      },
    ],
    action: {
      format: (data) => formatAction(data),
      upload: (data) => {
        setValue("file", data.data);
        trigger("file");
      },
      confirm: (data) => {
        dispatch(setProgress(true));

        return MachineAPI.checkFileNameRecord({
          data,
        });
      },
      formatMessage: (result) => {
        return (
          <>
            <div className="my-4">
              <h1 className="mb-2">{result.message}</h1>
              <h3 className="text-blue-400">
                前次檔案寄送時間：
                {moment(result.data.emailtime).format("YYYY/MM/DD HH:mm")}
              </h3>
            </div>
          </>
        );
      },
      reload: () => setPreReload((state) => ({ ...state, list: true })),
    },
    isShowChoose: true,
    sheetIndex: 1,
    methodOptions: {
      defval: "",
      raw: true,
      cellText: true,
      header: 1,
    },
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          date: yup.string().required("必填"),
          emailtime: yup.string().required("必填"),
          file: yup.object().required("必填"),
        })
        .required()
    );
  }, []);

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
      page: -1,
      size: -1,
    };

    SettingAPI.getModelTypeDataList({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, message } = result;
      if (Response === 1) {
        setModelList({
          raw: data,
          format: data.map((item) => item.model),
        });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  }, []);

  const formatAction = (data) => {
    const regex = /^[A-Z0-9-]+-[A-Z0-9-]+$/;
    let isValid = true,
      message = "",
      formatData = data.filter((item) => item[1] && regex.test(item[1])),
      keyword = "本月生產",
      monthIdx =
        data
          .find((item) => item.includes(keyword))
          ?.findIndex((item) => item === keyword) || null;

    if (monthIdx === null) {
      isValid = false;
      message = `${
        message ? "\n" : ""
      }無法判斷月份欄位，請確認月份左側欄位為『本月生產』`;
    }

    // 取當月各天資料
    formatData = formatData.map((item) => ({
      modelname: item[1],
      detail: item
        .slice(monthIdx + 1, monthIdx + 1 + 31)
        .map((item) => (typeof item === "string" ? 0 : item)),
    }));

    return {
      isValid,
      message,
      data: formatData,
    };
  };

  const onSubmit = (submitData) => {
    if (!submitData.file || submitData.file.data.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "匯入檔案無資料",
          detail: "",
        })
      );
      return;
    }

    dispatch(setProgress(true));

    let jsonData = {
      filename: submitData.file.filename,
      year: Number(moment(submitData.date).format("YYYY")),
      month: Number(moment(submitData.date).format("MM")),
      emailtime: moment(submitData.emailtime).format("YYYY-MM-DD HH:mm"),
      data: submitData.file.data
        .map((item) =>
          item.detail.map((numbers, nIdx) => ({
            modelname: item.modelname,
            date: `${moment(submitData.date).format("YYYY-MM")}-${
              nIdx + 1 < 10 ? `0${nIdx + 1}` : nIdx + 1
            }`,
            numbers,
          }))
        )
        .flat()
        .filter(
          (item) =>
            new Date(`${item.date} 00:00:00`) > new Date() &&
            modelList.format.includes(item.modelname) &&
            isExistDate({ date: item.date })
        ),
    };

    MachineAPI.importDailyProduceRecordData({
      data: jsonData,
    }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `匯入成功`,
            detail: "",
          })
        );
        closePrePop({ type: "excel" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `匯入失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              計畫表日期
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                  view: "month",
                  dateFormat: "yy/mm",
                  placeholder: "年/月",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="emailtime"
              className={classNames(
                { "p-error": errors.emailtime },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              檔案寄送時間
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormCalendar
                setting={setting}
                data={{
                  name: "emailtime",
                  showTime: true,
                  placeholder: "日期時間",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="file"
              className={classNames(
                { "p-error": errors.file },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              檔案
            </label>
            <div className="col-12 sm:col-9 py-0">{renderUpload()}</div>
          </div>

          <div className="text-right mt-3">
            <Button
              className="p-button-secondary p-button-outlined px-3"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: "excel" })}
            />
            <Button
              className="p-button-main ml-2 px-3"
              type="submit"
              label="儲存"
            />
          </div>
        </form>
      </div>
    </>
  );
}
