import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useAbortedEffect, useTable } from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";

import { Button } from "primereact/button";

export default function ModulePopMachineDailyScheduleDetail({
  prePopOption,
  closePrePop,
}) {
  const dispatch = useDispatch();
  const { setTbData, setTbColumns, renderTable } = useTable();

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
      date: prePopOption.data.date,
      modelname: prePopOption.data.modelname,
    };

    MachineAPI.getDailyProduceRecordByModelAndDate({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        data.forEach((item, index) => {
          let status = null,
            previous = index === 0 ? null : data[index - 1];
          if (previous) {
            item.status =
              previous.numbers > item.numbers
                ? -1
                : previous.numbers < item.numbers
                ? 1
                : null;
          }
        });
        if (data.length > 0) data[data.length - 1].isLast = true;
        setTbData(data);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  }, []);

  useEffect(() => {
    let temp = [
      {
        field: "updatedatetime",
        header: "變更時間",
      },
      {
        field: "numbers",
        header: "需求量",
      },
    ];
    setTbColumns(temp);
  }, []);

  const tbRowClass = (data, e) => {
    return {
      "bg-green-100": data.isLast && data.status === -1,
      "bg-red-100": data.isLast && data.status === 1,
    };
  };

  return (
    <>
      <div className="mx-3">
        <div className="text-lg font-bold text-blue-300">
          機種：{prePopOption.data.modelname}
        </div>

        {renderTable({
          className: "mt-2",
          isLazy: true,
          isPaginator: false,
          options: {
            scrollable: true,
            rowClassName: tbRowClass,
          },
        })}

        <div className="text-right mt-3">
          <Button
            className="p-button-secondary p-button-sm w-auto px-3"
            type="button"
            label="關閉"
            onClick={() => closePrePop({ type: "detail" })}
          />
        </div>
      </div>
    </>
  );
}
