import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  usePop,
} from "../../../../components/hooks";
import { AxiosMulti, formatApiResult } from "../../../../axios/request/common";
import axios from "axios";
import moment from "moment";

import ModuleMachineDailyProduceList from "./dailyProduceList";
import ModuleMachineDailyShipList from "./dailyShipList";
import ModulePopMachineDailyScheduleImport from "./popImport";
import ModulePopMachineDailyScheduleExport from "./popExport";
import ModulePopMachineDailyShipForm from "./popShipForm";
import { formatOptions } from "../../../../service/common";
import { system_dropdown } from "../../../../service/system";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

export default function ModuleMachineDailySchedule() {
  const dispatch = useDispatch();
  const [reload, setReload] = useState({ list: false });
  const [optionData, setOptionData] = useState(null);
  const dateInterval = 10;
  // 篩選條件
  const defaultFilter = {
      date: [
        new Date(moment().add(1, "days")),
        new Date(moment().add(dateInterval, "days")),
      ],
      type: null,
      days: dateInterval,
    },
    pagePath = "/machineDailySchedule";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "excel",
      title: () => "匯入",
      isOpen: false,
    },
    {
      key: "export",
      title: () => "匯出",
      isOpen: false,
    },
    {
      key: "ship",
      title: () => "出貨",
      isOpen: false,
    },
  ]);
  const [isShip, setIsShip] = useState(false);

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
      page: -1,
      size: -1,
    };

    AxiosMulti([
      SettingAPI.getModelTypesDistinct({
        data: params,
        options: { signal },
      }),
      SettingAPI.getModelTypeDataList({
        data: params,
        options: { signal },
      }),
    ]).then(
      axios.spread((result_modelType = {}, result_modelList = {}) => {
        let tempOption = {};

        let { data: result_modelType_data } = result_modelType;
        if (result_modelType.Response === 1) {
          tempOption = {
            ...tempOption,
            modelType: result_modelType_data.map((item) => ({
              label: item,
              value: item,
            })),
          };
        }

        let { data: result_modelList_data } = result_modelList;
        if (result_modelList.Response === 1) {
          result_modelList_data.sort(function (pre, next) {
            return pre.model.localeCompare(next.model);
          });
          tempOption = {
            ...tempOption,
            ...formatOptions(
              {
                modelList: result_modelList_data,
              },
              {
                uniqueKey: "model",
                showLabel: "model",
              }
            ),
          };
        }

        let isError = formatApiResult({
          data: [result_modelType, result_modelList],
          action: (message) => {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          },
        });
        if (isError) {
          dispatch(setProgress({ show: false }));
          return;
        }

        setOptionData(tempOption);
      })
    );
  }, []);

  // 日期 週期切換
  const weekChange = (type) => {
    let startDate;
    if (type === "pre") {
      startDate = new Date(
        moment(filterData.date[0]).subtract(dateInterval, "days")
      );
    } else if (type === "next") {
      startDate = new Date(
        moment(filterData.date[0]).add(dateInterval, "days")
      );
    }
    dateChange([startDate, ""]);
  };

  const dateChange = (date) => {
    let chooseDate = date[0];
    let startDate = new Date(chooseDate);
    let endDate = new Date(moment(chooseDate).add(dateInterval - 1, "days"));

    setFilterData((state) => ({ ...state, date: [startDate, endDate] }));
  };

  return (
    <>
      <div className="mt-2">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 sm:col-6 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  日期區間
                </label>
                <div className="date-range-wrap p-my-2">
                  <div
                    className="date-icon"
                    tabIndex="0"
                    onClick={() => weekChange("pre")}
                  >
                    <i className="pi pi-chevron-left"></i>
                  </div>
                  <div className="date-main">
                    <label htmlFor="date-range">
                      {filterData.date
                        ? moment(filterData.date[0]).format("YYYY/MM/DD") +
                          " - " +
                          moment(filterData.date[1]).format("YYYY/MM/DD")
                        : ""}
                    </label>
                    <Calendar
                      inputId="date-range"
                      className="p-inputtext-sm col-help"
                      name="dateRange"
                      value={
                        filterData.date[0] && filterData.date[1]
                          ? [
                              new Date(filterData.date[0]),
                              new Date(filterData.date[1]),
                            ]
                          : []
                      }
                      onChange={(e) => dateChange(e.value)}
                      dateFormat="yy/mm/dd"
                      selectionMode="range"
                      readOnlyInput
                      showButtonBar
                    />
                  </div>
                  <div
                    className="date-icon"
                    tabIndex="1"
                    onClick={() => weekChange("next")}
                  >
                    <i className="pi pi-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="col-12 sm:col-6 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="type"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  機型
                </label>
                <Dropdown
                  name="type"
                  className="w-full"
                  value={filterData.type}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.modelType ?? []}
                  filter
                  filterBy="value"
                  emptyMessage={system_dropdown.empty}
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div>
              <div
                className={classNames(
                  "col-12 xl:col-6 flex align-items-end mt-2 xl:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto xl:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() =>
                    setSearch({
                      ...filterData,
                    })
                  }
                />

                {isShip ? (
                  <Button
                    className="p-button-main p-button-sm w-auto ml-2 xl:ml-auto px-3"
                    type="button"
                    label="新增"
                    onClick={() => openPop({ type: "ship", data: null })}
                  />
                ) : (
                  <>
                    <Button
                      className="p-button-warning p-button-sm w-auto xl:ml-auto ml-2 px-3"
                      type="button"
                      label="日程匯入"
                      onClick={() => openPop({ type: "excel", data: null })}
                    />
                    <Button
                      className="p-button-main p-button-sm w-auto ml-2 px-3"
                      type="button"
                      label="日程匯出"
                      onClick={() => openPop({ type: "export", data: null })}
                    />
                  </>
                )}
              </div>
            </>
          ),
        })}

        {/* <div className="flex align-items-center my-2 ml-1">
          <span className="font-bold">切換：</span>
          <InputSwitch
            className="mr-1"
            style={{ transform: "scale(0.8)" }}
            checked={isShip}
            onChange={(e) => {
              setIsShip(e.value);
              setReload((state) => ({
                ...state,
                list: true,
              }));
            }}
          />
          <span
            className={classNames("font-bold", {
              "text-gray-600": !isShip,
              "text-blue-500": isShip,
            })}
          >
            出貨管理
          </span>
        </div> */}

        {isShip ? (
          <ModuleMachineDailyShipList
            preReload={reload}
            setPreReload={setReload}
            search={search}
            setFilterData={setFilterData}
            setSearch={setSearch}
            pagePath={pagePath}
            optionData={optionData}
            dateInterval={dateInterval}
          />
        ) : (
          <ModuleMachineDailyProduceList
            preReload={reload}
            setPreReload={setReload}
            search={search}
            setFilterData={setFilterData}
            setSearch={setSearch}
            pagePath={pagePath}
            optionData={optionData}
            dateInterval={dateInterval}
          />
        )}
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-35rem",
        children: (
          <>
            {isPop.excel?.isOpen && (
              <ModulePopMachineDailyScheduleImport
                prePopOption={popOption}
                closePrePop={closePop}
                setPreReload={setReload}
              />
            )}
            {isPop.export?.isOpen && (
              <ModulePopMachineDailyScheduleExport
                prePopOption={popOption}
                closePrePop={closePop}
              />
            )}
            {isPop.ship?.isOpen && (
              <ModulePopMachineDailyShipForm
                prePopOption={popOption}
                closePrePop={closePop}
                optionData={optionData}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
