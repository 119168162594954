import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { SettingAPI, ShipAPI } from "../../../axios/api";
import {
  useAbortedEffect,
  useHookForm,
  usePop,
} from "../../../components/hooks";
import { AxiosMulti, formatApiResult } from "../../../axios/request/common";
import axios from "axios";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import ModulePopConfirm from "./popConfirm";
import {
  FormInputnumber,
  FormDropdown,
  FormCalendar,
} from "../../../components/formElement/index";
import { formatResData, checkFormUpdate } from "../../../service/common";
import { option_enabled } from "../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackShipDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    {
      key: 1,
      label: "出貨管理",
      command: () => route_navigate("/back/ship"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  // 明細資料
  const [apiData, setApiData] = useState({});
  const [modelData, setModelData] = useState([]);
  // 表單欄位預設值
  const defaultValues = {
    date: null,
    modelname: null,
    numbers: null,
    type: 1, // 固定傳1
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "confirm",
      title: () => "資料重複",
      isOpen: false,
    },
  ]);

  useEffect(() => {
    setSchema(
      yup
        .object({
          date: yup.string().required("必填").nullable(),
          modelname: yup.string().required("必填"),
          numbers: yup.number().required("必填").nullable(),
        })
        .required()
    );
  }, []);

  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        setting: {
          page: -1,
        },
        ship: {
          id: route_params.detailid,
        },
      };

      let promise = [
        SettingAPI.getModelTypeDataList({
          data: params.setting,
          options: { signal },
        }),
      ];

      if (route_params.detailid !== "add") {
        promise.push(
          ShipAPI.getShipRecordsDetails({
            data: params.ship,
            options: { signal },
          })
        );
      }

      AxiosMulti(promise).then(
        axios.spread((result_model = {}, result_detail = {}) => {
          let tempOption = {};

          let { data: result_model_data } = result_model;
          if (result_model.Response === 1) {
            setModelData(
              result_model_data.map((item) => ({
                label: item.model,
                value: item.model,
              }))
            );
          }

          let { data: result_detail_data } = result_detail;
          if (result_detail.Response === 1) {
            let temp = formatResData(defaultValues, result_detail_data);
            setApiData(temp);
            reset(temp);
            setReload((state) => ({ ...state, list: false }));
          }

          let isError = formatApiResult({
            data: [result_model, result_detail],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress({ show: false }));
            return;
          }

          dispatch(setProgress(false));
        })
      );
    },
    [reload]
  );

  const onSubmit = (submitData) => {
    let isAdd = route_params.detailid === "add";
    saveData({ isAdd });
  };

  const saveData = ({ isAdd = false, id = route_params.detailid }) => {
    let submitData = getValues();

    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let jsonData = {
      ...submitData,
    };

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/ship");
      return;
    }

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = ShipAPI.setShipRecordsData({
        data: jsonData,
      });
      toast = "新增";
    } else {
      jsonData.id = Number(id);
      promise = ShipAPI.updateShipRecordsData({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        route_navigate("/back/ship");
      } else if (Response === 2) {
        openPop({
          type: "confirm",
          data: {
            ...data,
            numbers_new: submitData.numbers,
          },
        });
        dispatch(setProgress(false));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">出貨管理 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>日期
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                  disabled: route_params.detailid !== "add",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="modelname"
              className={classNames(
                { "p-error": errors.modelname },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>機種
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                setting={setting}
                data={{
                  name: "modelname",
                  options: modelData ?? [],
                  optionLabel: "label",
                  optionValue: "value",
                  filter: true,
                  filterBy: "label",
                  showClear: false,
                  disabled: route_params.detailid !== "add",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="numbers"
              className={classNames(
                { "p-error": errors.numbers },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>出貨數量
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputnumber
                setting={setting}
                data={{
                  name: "numbers",
                }}
              />
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/ship")}
            />
            <Button
              type="submit"
              label="儲存"
              className="p-button-main ml-2 px-3"
            />
          </div>
        </form>
      </div>

      {renderDialog({
        className: classNames("w-11 md:w-10 lg:w-8", {
          "max-w-35rem": isPop.confirm?.isOpen,
        }),
        children: (
          <>
            {isPop.confirm?.isOpen && (
              <ModulePopConfirm
                prePopOption={popOption}
                closePrePop={closePop}
                confirmAction={({ id }) => saveData({ isAdd: false, id })}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
