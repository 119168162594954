import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useHookForm } from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import moment from "moment";

import {
  FormCalendar,
  FormDropdown,
  FormInputnumber,
} from "../../../../components/formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { formatResData } from "../../../../service/common";

export default function ModulePopMachineDailyShipForm({
  prePopOption,
  closePrePop,
  optionData,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    modelname: "",
    date: moment().format("YYYY/MM/DD"),
    amount: null,
  };
  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    yup,
    setSchema,
    errors,
    setting,
  } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          modelname: yup.string().required("必填"),
          date: yup.string().required("必填"),
          amount: yup.number().required("必填").nullable(),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    if (!prePopOption.data) return;

    reset(formatResData(defaultValues, prePopOption.data));
  }, [prePopOption.data]);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      ...submitData,
    };

    dispatch(setProgress(false));
    return;

    MachineAPI.getDailyProduceRecordList({
      data: jsonData,
    }).then((result) => {
      let { Response, data, message } = result;
      if (Response === 1) {
        dispatch(
          setToast({
            severity: "error",
            summary: "異動成功",
            detail: "",
          })
        );
        closePrePop({ type: "ship" });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <div className="mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              機種
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormDropdown
                setting={setting}
                data={{
                  name: "modelname",
                  options: optionData.modelList || [],
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  filter: true,
                  filterBy: "showLabel",
                  showClear: false,
                  disabled: prePopOption.data,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              日期
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                  disabled: prePopOption.data,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              已出貨數量
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormInputnumber
                setting={setting}
                data={{
                  name: "amount",
                }}
              />
            </div>
          </div>

          <div className="text-right mt-3">
            <Button
              className="p-button-secondary p-button-outlined px-3"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: "ship" })}
            />
            <Button
              className="p-button-main ml-2 px-3"
              type="submit"
              label="儲存"
            />
          </div>
        </form>
      </div>
    </>
  );
}
