import {
  createXlsxColumn,
  createXlsxCellStyle,
  createColumnWidth,
  createColumnHeight,
  createColumnMerge,
  parseSheetName,
  xlsxCellSerial,
} from "../../../../../service/excel";

export const createXlsxDailyProduceRecord = ({
  title,
  data: { workbook, headColumns, data },
}) => {
  const ws = workbook.addWorksheet(title);
  const commonStyles = { align_h: "center", wrapText: false };
  const headStyles = { fill_color: "d9e1f2", font_bold: true };

  headColumns.forEach((column, cIdx) => {
    createXlsxColumn({
      ws,
      column: `${xlsxCellSerial[cIdx]}1`,
      value: column.header,
      styles: createXlsxCellStyle({
        ...headStyles,
        font_bold: true,
        align_h: "left",
      }),
    });
  });

  data.map((item, index) => {
    headColumns.forEach((column, cIdx) => {
      createXlsxColumn({
        ws,
        column: `${xlsxCellSerial[cIdx]}${2 + index}`,
        value: ["modelKey", "produce_amount", "ship_amount"].includes(
          column.key
        )
          ? item[column.key]
          : String(item[column.key]?.latest_number ?? "-"),
        styles: createXlsxCellStyle({
          ...commonStyles,
          align_h: ["modelKey"].includes(column.key) ? "left" : "center",
          fill_color:
            item[column.key]?.status === -1
              ? "c8e2d6"
              : item[column.key]?.status === 1
              ? "f7cfd2"
              : "ffffff",
        }),
      });
    });
  });

  createColumnWidth({
    ws,
    columns: headColumns.map((column, cIdx) => ({
      column: xlsxCellSerial[cIdx],
      width: cIdx === 0 ? 25 : [1, 2].includes(cIdx) ? 17 : 10,
    })),
  });

  // let columnHeight = [];
  // for (let i = 0; i < 10 + boundaryData.data.length; i++) {
  //   columnHeight.push({ column: i + 1, height: 24 });
  // }
  // createColumnHeight({
  //   ws,
  //   columns: columnHeight,
  // });
  createColumnMerge({
    ws,
    columns: [],
  });
};

const createDetail = ({
  ws,
  headStyles,
  commonStyles,
  rowIdx,
  gwp_type_obj,
  data,
}) => {
  let merges = [];

  createXlsxColumn({
    ws,
    column: `B${rowIdx}`,
    value: "配銷階段使用物料(包裝材等或能源) ",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
      align_h: "left",
    }),
  });
  createXlsxColumn({
    ws,
    column: `B${rowIdx + 1}`,
    value: "分類",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `C${rowIdx + 1}`,
    value: "名稱",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
      wrapText: true,
    }),
  });
  createXlsxColumn({
    ws,
    column: `D${rowIdx + 1}`,
    value: "排放係數",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `E${rowIdx + 1}`,
    value: "係數值",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `F${rowIdx + 1}`,
    value: "單位",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `G${rowIdx + 1}`,
    value: "活動數據",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `H${rowIdx + 1}`,
    value: `小計（kg${gwp_type_obj.CO2.showLabel}e）`,
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `I${rowIdx + 1}`,
    value: "資料來源",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });

  data.forEach((item, index) => {
    let isActual = item.main.calculate_type === 1;
    createXlsxColumn({
      ws,
      column: `B${rowIdx + 2 + index}`,
      value: "配銷階段",
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `C${rowIdx + 2 + index}`,
      value: isActual ? item.main.name : item.sub.categoryData.name,
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `D${rowIdx + 2 + index}`,
      value: isActual ? "-" : item.sub.categoryData.factor_info.name,
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `E${rowIdx + 2 + index}`,
      value: isActual ? "-" : item.sub.categoryData.factor_info.co2e_factor,
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `F${rowIdx + 2 + index}`,
      value: isActual ? "-" : item.sub.categoryData.factor_info.unit,
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `G${rowIdx + 2 + index}`,
      value: isActual
        ? "-"
        : (item.sub.categoryData.cf_s3c3_an || []).reduce(
            (pre, cur) =>
              (pre +=
                (cur.numbers || 0) *
                (cur.unit_numbers || 0) *
                (cur.activity_numbers || 0)),
            0
          ),
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `H${rowIdx + 2 + index}`,
      value: Math.round(item.sub.total_kg_co2e * 10000) / 10000,
      styles: createXlsxCellStyle({
        ...commonStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `I${rowIdx + 2 + index}`,
      value: isActual
        ? "-"
        : {
            text: `${parseSheetName(item.main.name)}${
              index + 1
            } ${parseSheetName(item.sub.categoryData.name)}`,
            hyperlink: `#\'${parseSheetName(item.main.name)}${
              index + 1
            } ${parseSheetName(item.sub.categoryData.name)}\'!B2`,
            tooltip: "連結sheet",
          },
      styles: createXlsxCellStyle({
        ...commonStyles,
        align_h: "left",
        font_color: "2295f9",
        font_underline: true,
      }),
    });
  });

  merges = [...merges, { column: `B${rowIdx}:I${rowIdx}` }];

  return {
    merges,
  };
};

const createCalculate = ({
  ws,
  headStyles,
  commonStyles,
  rowIdx,
  gwp_type_obj,
  cfp_product_info,
  data,
}) => {
  let merges = [],
    total_raw = data.sub.reduce((pre, cur) => (pre += cur.total_kg_co2e), 0),
    distributePer =
      data.distribute_type === 1
        ? cfp_product_info.product_turnover / cfp_product_info.total_turnover
        : cfp_product_info.product_working_hours /
          cfp_product_info.total_working_hours;

  createXlsxColumn({
    ws,
    column: `B${rowIdx}`,
    value: "分配法計算",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
      align_h: "left",
    }),
  });

  if (data.calculate_type === 2) {
    createXlsxColumn({
      ws,
      column: `B${rowIdx + 1}`,
      value: "二氧化碳當量",
      styles: createXlsxCellStyle({
        ...commonStyles,
        ...headStyles,
      }),
    });
    createXlsxColumn({
      ws,
      column: `C${rowIdx + 1}`,
      value: "X",
      styles: createXlsxCellStyle({
        ...commonStyles,
        ...headStyles,
        fill_color: "e2efda",
      }),
    });
    createXlsxColumn({
      ws,
      column: `D${rowIdx + 1}`,
      value: `分配法 - ${data.distribute_type === 1 ? "營業額" : "工時"}`,
      styles: createXlsxCellStyle({
        ...commonStyles,
        ...headStyles,
      }),
    });
  }
  createXlsxColumn({
    ws,
    column: `E${rowIdx + 1}`,
    value: "/",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
      fill_color: "e2efda",
    }),
  });
  createXlsxColumn({
    ws,
    column: `F${rowIdx + 1}`,
    value: "總產量",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `G${rowIdx + 1}`,
    value: "=",
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
      fill_color: "e2efda",
    }),
  });
  createXlsxColumn({
    ws,
    column: `H${rowIdx + 1}`,
    value: `總二氧化碳當量（kg${gwp_type_obj.CO2.showLabel}e）`,
    styles: createXlsxCellStyle({
      ...commonStyles,
      ...headStyles,
    }),
  });

  createXlsxColumn({
    ws,
    column: `B${rowIdx + 2}`,
    value: String(Math.round(total_raw * 10000) / 10000),
    styles: createXlsxCellStyle({
      ...commonStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `D${rowIdx + 2}`,
    value: `${Math.round(distributePer * 10000) / 100}%`,
    styles: createXlsxCellStyle({
      ...commonStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `F${rowIdx + 2}`,
    value: String(cfp_product_info.total_output_number || 0),
    styles: createXlsxCellStyle({
      ...commonStyles,
    }),
  });
  createXlsxColumn({
    ws,
    column: `H${rowIdx + 2}`,
    value: cfp_product_info.total_output_number
      ? String(
          Math.round(
            (data.calculate_total_kg_co2e /
              cfp_product_info.total_output_number) *
              10000
          ) / 10000
        )
      : "0",
    styles: createXlsxCellStyle({
      ...commonStyles,
    }),
  });

  merges = [
    ...merges,
    { column: `B${rowIdx}:H${rowIdx}` },
    { column: `C${rowIdx + 1}:C${rowIdx + 2}` },
    { column: `E${rowIdx + 1}:E${rowIdx + 2}` },
    { column: `G${rowIdx + 1}:G${rowIdx + 2}` },
  ];

  return {
    merges,
  };
};
