import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useTable,
  usePop,
} from "../../../../components/hooks";
import { MachineAPI, SettingAPI } from "../../../../axios/api";
import moment from "moment";

import ModulePopMachineDailyShipForm from "./popShipForm";
import { setPageSetting, formatOptions } from "../../../../service/common";

export default function ModuleMachineDailyShipList({
  preReload,
  setPreReload,
  search,
  setFilterData,
  setSearch,
  pagePath,
  optionData,
  dateInterval,
}) {
  const dispatch = useDispatch();
  const {
    tbData,
    setTbData,
    setRawTbData,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "ship",
      title: () => "出貨",
      isOpen: false,
    },
  ]);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!preReload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        date: moment(search.date[0]).format("YYYY-MM-DD"),
      };

      MachineAPI.getDailyProduceRecordList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;
        if (Response === 1) {
          data = Object.entries(data.ship_records).map(([key, val]) => ({
            model: key,
            ...val.reduce(
              (pre, cur) => ({
                ...pre,
                [moment(cur.date).format("MM/DD")]: cur,
                [`${moment(cur.date).format("MM/DD")}_format`]:
                  cur.latest_number ?? null,
              }),
              {}
            ),
          }));
          data.sort(function (pre, next) {
            return pre.model.localeCompare(next.model);
          });
          setTbData(data);
          setPreReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }
        dispatch(setProgress(false));
      });
    },
    [preReload, optionData]
  );

  useEffect(() => {
    let temp = [
      {
        key: "model",
        field: "model",
        header: "機種",
        className: "flex-grow-1 w-10rem",
        isSort: true,
        sortType: "string",
      },
    ];
    if (search?.date[0] && search?.date[1]) {
      for (let i = 0; i < dateInterval; i++) {
        let nowDate = moment(search?.date[0]).add(i, "days").format("MM/DD");
        temp.push({
          key: `${nowDate}_format`,
          field: `${nowDate}_format`,
          header: nowDate,
          body: (data) => <>{data[nowDate]?.latest_number ?? "-"}</>,
          className: "flex-grow-1 w-3rem",
          isSort: true,
          sortType: "number",
        });
      }
    }
    setTbColumns(temp);
  }, [pagePath, search]);

  const cellSelect = ({ field, rowIndex }) => {
    let data = tbData[rowIndex][field];
    if (field !== "model") {
      openPop({
        type: "ship",
        data: {
          ...data,
          amount: data.latest_number,
        },
      });
    }
  };

  const tbCellClass = (val, { field, rowIndex }) => {
    let data = tbData[rowIndex][field] || {};
    return "cursor-pointer hover:bg-blue-100";
  };

  return (
    <>
      {renderTable({
        className: "mt-2",
        isLazy: true,
        isPaginator: false,
        options: {
          resizableColumns: true,
          columnResizeMode: "fit",
          cellClassName: tbCellClass,
          cellSelection: true,
          onCellSelect: cellSelect,
        },
      })}

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-35rem",
        children: (
          <>
            {isPop.ship?.isOpen && (
              <ModulePopMachineDailyShipForm
                prePopOption={popOption}
                closePrePop={closePop}
                optionData={optionData}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
