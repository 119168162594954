import request from "../request";
import Url from "../url/machine";

const getDailyProduceRecordList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getDailyProduceRecordList + path, data, options);
};
const getDailyProduceRecordByModelAndDate = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.get(
    Url.getDailyProduceRecordByModelAndDate + path,
    data,
    options
  );
};
const getDailyImportFileRecordList = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.get(Url.getDailyImportFileRecordList + path, data, options);
};
const checkFileNameRecord = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.checkFileNameRecord + path, data, options);
};
const importDailyProduceRecordData = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.importDailyProduceRecordData + path, data, options);
};

export default {
  getDailyProduceRecordList,
  getDailyProduceRecordByModelAndDate,
  getDailyImportFileRecordList,
  checkFileNameRecord,
  importDailyProduceRecordData,
};
