import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { AccountAPI } from "../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
  usePop,
} from "../../../components/hooks";

import ModuleBreadCrumb from "../../../components/breadCrumb";
// import ModulePopMemberAccount from "./popAccount";
// import ModulePopContract from "./popContract";
import {
  formatOptions,
  setPageSetting,
  formatTreeData,
} from "../../../service/common";
import { system_dropdown, system_table } from "../../../service/system";
import { option_enabled } from "../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function BackAccountList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const crumbItem = [{ key: 1, label: "帳號管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      type: null,
      departmentid: null,
    },
    pagePath = "/account";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "account",
      title: () => "",
      isOpen: false,
    },
    {
      key: "contract",
      title: () => "新增勞動契約",
      isOpen: false,
    },
  ]);
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    setOptionData({
      ...formatOptions({
        enabled: option_enabled,
      }),
    });
  }, []);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      AccountAPI.getAllMemebrList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    if (!optionData) return;

    const renderBtnAction = (data) => (
      <>
        <Button
          className="p-button-main p-button-sm"
          type="button"
          label="編輯"
          onClick={() => route_navigate(`/back${pagePath}/${data.id}`)}
        />
      </>
    );

    setTbColumns([
      {
        field: "name",
        header: "人員姓名",
      },
      {
        field: "username",
        header: "帳號",
      },
      {
        field: "enabled",
        header: "啟用狀態",
        body: (data) => (
          <>
            <div
              className={classNames({
                "text-green-400": data.enabled === 1,
                "text-red-400": data.enabled === 0,
              })}
            >
              {optionData.enabled_obj?.[data.enabled].showLabel}
            </div>
          </>
        ),
      },
      {
        field: "id",
        header: "進階",
        className: "w-5rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath, optionData]);

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">帳號管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  人事狀態
                </label>
                <Dropdown
                  name="type"
                  className="w-full"
                  value={filterData.type}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.member_type ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyMessage={system_dropdown.empty}
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div>
              <div
                className={classNames(
                  "col-12 md:col-9 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                />

                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => route_navigate(`/back${pagePath}/add`)}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true },
        })}
      </div>

      {/* {renderDialog({
        className: classNames("w-11 md:w-10 lg:w-8", {
          "max-w-35rem": isPop.account?.isOpen,
        }),
        children: (
          <>
            {isPop.account?.isOpen && (
              <ModulePopMemberAccount
                prePopOption={popOption}
                closePrePop={closePop}
                setPreReload={setReload}
              />
            )}
            {isPop.contract?.isOpen && (
              <ModulePopContract
                prePopOption={popOption}
                closePrePop={closePop}
                setPreReload={setReload}
              />
            )}
          </>
        ),
      })} */}
    </>
  );
}
